import React from 'react'
import styled from 'styled-components'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import Text from './../Text'
import DietarySelector from '../../lib/DietarySelector'
import { getConfigFor } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'
import { SCREEN_SIZE } from '../../lib/Media'

const Separator = styled.span`
  margin: 0 2px;
`

const DietaryAttributeList = styled.div`
  display: flex;
  white-space: nowrap;
`

const Elipsis = styled.span`
  white-space: nowrap;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  &.quantity {
    max-width: 7.75rem;
    ${SCREEN_SIZE.From.Tablet} {
      max-width: 9.25rem;
    }
    ${SCREEN_SIZE.From.Hd} {
      max-width: 10rem;
    }
  }
`

const Subtitle = styled(Text)`
  color: ${defaultTheme.ColorBaseMineShaft500} !important;
`

export const ProductDetailMetaData = ({ metaData, brandInfo }) => {
  const { isEnabled: isPricePerPieceEnabled } = getConfigFor(
    SPLIT_FEATURES.PRICE_PER_PIECE_ENABLED
  )

  const hasMetaData = Boolean(
    metaData?.DisplayUnit ||
      (isPricePerPieceEnabled ? metaData?.pricePerUnit : false)
  )

  if (!hasMetaData) {
    return null
  }

  return (
    <>
      <Elipsis className="quantity">
        <Subtitle size="small">
          <span>{metaData?.DisplayUnit}</span>
          {Boolean(isPricePerPieceEnabled && metaData?.pricePerUnit) && (
            <>
              <Separator data-testid="price-per-unit-separator">
                &bull;
              </Separator>
              <span>${metaData.pricePerUnit}</span>
            </>
          )}
        </Subtitle>
      </Elipsis>
      {brandInfo && brandInfo.name && (
        <Separator data-testid="brand-info-separator">|</Separator>
      )}
    </>
  )
}

const ProductCardMetaData = ({ metaData }) => {
  const { isEnabled: isPricePerPieceEnabled } = getConfigFor(
    SPLIT_FEATURES.PRICE_PER_PIECE_ENABLED
  )

  const hasMetaData = Boolean(
    metaData &&
      (metaData.DisplayUnit ||
        metaData.DietaryAttributes ||
        (isPricePerPieceEnabled ? metaData.pricePerUnit : false))
  )

  const DietaryAttributes = DietarySelector(metaData)
  const hasDietaryAttributes = DietaryAttributes.length > 0

  return (
    <>
      {hasMetaData && (
        <DietaryAttributeList>
          {metaData.DisplayUnit && (
            <Text size="small" color={defaultTheme.ColorBaseMineShaft500}>
              {metaData.DisplayUnit}
            </Text>
          )}
          {hasDietaryAttributes &&
            DietaryAttributes.map((dietary, index) => (
              <Text
                size="small"
                color={defaultTheme.ColorBaseMineShaft500}
                key={dietary}
              >
                {!metaData.DisplayUnit && index === 0 ? (
                  ''
                ) : (
                  <Separator data-testid="dietary-attributes-separator">
                    &bull;
                  </Separator>
                )}
                {dietary}
              </Text>
            ))}
          {Boolean(isPricePerPieceEnabled && metaData.pricePerUnit) && (
            <Text size="small" color={defaultTheme.ColorBaseMineShaft500}>
              {Boolean(metaData.DisplayUnit || hasDietaryAttributes) && (
                <Separator data-testid="price-per-unit-separator">
                  &bull;
                </Separator>
              )}
              ${metaData.pricePerUnit}
            </Text>
          )}
        </DietaryAttributeList>
      )}
    </>
  )
}

export default ProductCardMetaData
